module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["fr","en"],"defaultLanguage":"fr","siteUrl":"https://avocatsdivorceseparation.ca","redirect":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-RBY6LEGYWX","AW-996648267",""],"gtagConfig":{"anonymize_up":true},"pluginConfig":{"head":true}},
    }]
